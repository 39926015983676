























































import { Component } from "vue-property-decorator";
import { AccountService, CurrentProfile } from "@/core/services";
import { images } from "@/core/constants";
import { Message, Val } from "element-ui";
import AppVue from "@/AppVue.vue";
import { TermsAndConditionsCpt } from ".";
import { FooterCpt } from "@/core/components";
import { zohoManager } from "@/zoho/zoho";

@Component({
  components: {
    TermsAndConditionsCpt: () => Promise.resolve(TermsAndConditionsCpt),
    FooterCpt: () => Promise.resolve(FooterCpt),
  },
})
export default class OverlappingLoginView extends AppVue {
  loading: boolean = false;
  centerDialogVisible: boolean = false;
  year = new Date().getFullYear();
  mail = false;

  model = {};
  logo = images.cisLogoCropped;

  async created() {}
  async logoutOtherSessions() {
    Message.closeAll();
    this.loading = true;
    try {
      const response = await AccountService.forcelogin(this.mail);
      AccountService.setToken(response.token);
      if (response) {
        this.loading = false;
        const profile = await AccountService.getProfile();
        zohoManager.UpdateUser(profile.email);
        this.redirectToResult();
      }
    } catch (e) {
      this.$notify({
        title: "Login failed",
        showClose: true,
        message: "Incorrect username/password provided",
        type: "error",
        duration: 0,
      });
    } finally {
      this.loading = false;
    }
  }
  async logout() {
    await AccountService.logout();
    this.$router.push("/login").catch(() => {});
  }
  redirectToResult() {
    if (this.$auth.isAuthenticated()) {
      if (this.$route.query.redirectTo) {
        this.trackEvent("Login", { name: "login-redirect", route: this.$route.query.redirectTo });
        this.$router.push(this.$route.query.redirectTo as string);
        return;
      }
      this.$router.push("/dashboard").catch(() => {});
    } else {
      window.location.reload();
    }
  }

  mounted() {
    super.updateTitle("Log in");
  }
}
