























































































































import { Component, Vue, Prop } from "vue-property-decorator";

import {} from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";

export class Type {}

@Component({
  components: {},
})
export default class TermsAndConditionsCpt extends AppVue {
  @Prop({ default: false }) loading: boolean;

  async accept() {
    this.$emit("accept");
  }
  async cancel() {
    this.$emit("cancel");
  }
  async created() {}

  async mounted() {}
}
