











































import { Component } from "vue-property-decorator";
import { AccountService, CurrentProfile } from "@/core/services";
import { images } from "@/core/constants";
import { Message, Val } from "element-ui";
import AppVue from "@/AppVue.vue";
import { TermsAndConditionsCpt } from ".";
import { FooterCpt } from "@/core/components";
import { zohoManager } from "@/zoho/zoho";

@Component({
  components: {
    TermsAndConditionsCpt: () => Promise.resolve(TermsAndConditionsCpt),
    FooterCpt: () => Promise.resolve(FooterCpt),
  },
})
export default class LoggedOutView extends AppVue {
  loading: boolean = false;
  centerDialogVisible: boolean = false;
  year = new Date().getFullYear();

  model = {};
  logo = images.cisLogoCropped;

  async created() {}

  mounted() {
    super.updateTitle("Logged out");
  }
}
