












































import { Component, Vue } from "vue-property-decorator";
import {} from "@/core/services";
import {} from "@/core/components";
import {} from "@/core/models";
import { Message, Val } from "element-ui";
import { AccountService } from "@/core/services";
import { images } from "@/core/constants";
import { FooterCpt } from "@/core/components";

import AppVue from "@/AppVue.vue";

@Component({
  components: {
    FooterCpt: () => Promise.resolve(FooterCpt),
  },
})
export default class ResetPasswordRequestView extends AppVue {
  disabled = false;
  displayMessage = false;
  loading = false;
  message = "";
  rules = {
    email: [
      {
        required: true,
        message: "Your email address is required",
        trigger: "blur",
      },
      { min: 3, max: 5, message: "Length should be 3 to 5", trigger: "blur" },
    ],
  };
  form = {
    email: "",
  };
  year = new Date().getFullYear();

  logo = images.cisLogoCropped;

  async created() {}

  async mounted() {}

  async resetPassword(formRef: string) {
    Message.closeAll();
    this.loading = true;
    this.disabled = true;
    try {
      const response = await AccountService.resetPassword(this.form);
    } catch (e) {
    } finally {
      this.disabled = false;
      this.loading = false;
    }

    this.$notify.success({
      title: "Reset password",
      message: "A password reset link has been sent to your email address",
      type: "success",
      duration: 0,
      showClose: true,
    });
    this.displayMessage = true;
    this.message = "A password reset link has been sent to your email address";

    this.$router.push("/login").catch(() => {});
  }
}
