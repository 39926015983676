import {
  LoginView, ResetPasswordView, ChangePasswordView,
  ResetPasswordRequestView, ConfirmEmailView, TermsAndConditionsCpt, OverlappingLoginView,
} from ".";
import { LoggedOutView } from "./index";

export const authRoutes = [
  {
    path: "/termsAnConditions",
    name: "terms",
    meta: { layout: "empty", allowAnonymous: true },
    component: TermsAndConditionsCpt,
  },
  {
    path: "/login",
    name: "login",
    meta: { layout: "empty", allowAnonymous: true },
    component: LoginView,
  },
  {
    path: "/login/overlapping",
    name: "overlapping-login",
    meta: { layout: "empty", allowAnonymous: false },
    component: OverlappingLoginView,
  },
  {
    path: "/loggedout",
    name: "logged-out",
    meta: { layout: "empty", allowAnonymous: true },
    component: LoggedOutView,
  },
  {
    path: "/cis/login/:loginKey",
    name: "loginCis",
    meta: { layout: "empty", allowAnonymous: true },
    component: LoginView,
  },
  {
    path: "/auth/confirm/:emailConfirmationToken/:userEmailAddress",
    name: "confirmEmail",
    meta: { layout: "empty", allowAnonymous: true },
    component: ConfirmEmailView,
  },
  {
    path: "/auth/resetPassword/:emailConfirmationToken/:userEmailAddress",
    name: "resetPassword",
    meta: { layout: "empty", allowAnonymous: true },
    component: ResetPasswordView,
  },
  {
    path: "/auth/resetPassword",
    name: "resetPasswordRequest",
    meta: { layout: "empty", allowAnonymous: true },
    component: ResetPasswordRequestView,
  },
  {
    path: "/auth/changePassword",
    name: "changePassword",
    meta: { layout: "basic", allowAnonymous: false },
    component: ChangePasswordView,
  },
];
