




















































import { Message, Val } from "element-ui";
import { images } from "@/core/constants";
import { Component, Vue } from "vue-property-decorator";
import { AccountService, ChangePasswordModel } from "@/core/services";
import {} from "@/core/components";
import {} from "@/core/models";

import AppVue from "@/AppVue.vue";

@Component({
  components: {},
})
export default class ChangePasswordView extends AppVue {
  disabled = false;
  displayMessage = false;
  loading = false;
  message = "";
  rules = {
    currentPassword: [
      {
        required: true,
        message: "Your current password is required",
        trigger: "blur",
      },
      { min: 8, message: "Length should be at least 8 characters", trigger: "blur" },
    ],
    newPassword: [
      {
        required: true,
        message: "The new password is required",
        trigger: "blur",
      },
      { min: 8, message: "Length should be at least 8 characters", trigger: "blur" },
    ],
    confirmPassword: [
      {
        validator: (rule: any, value: string, callback: any) => {
          if (value === this.form.newPassword) {
            callback();
          } else {
            callback(new Error("Passwords don't match"));
          }
        },
      },
    ],
  };
  form: ChangePasswordModel = {
    email: "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  year = new Date().getFullYear();

  logo = images.cisLogo;
  async created() {}

  async mounted() {}
  async changePassword(confirmFormRef: any) {
    this.loading = true;
    this.disabled = true;
    try {
      this.form.email = this.$auth.getPayload()["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"];
      const response = await AccountService.changePassword(this.form);
      this.notify("Success", "The password has been updated.", "success");
      this.$router.push("/").catch(() => {});
    } catch (err) {
      this.displayMessage = true;
      this.message = err.message;
    } finally {
      this.loading = false;
      this.disabled = false;
    }
  }
}
