
















































import { Message, Val } from "element-ui";
import { images } from "@/core/constants";
import { Component, Vue } from "vue-property-decorator";
import { AccountService } from "@/core/services";
import {} from "@/core/components";
import {} from "@/core/models";
import { FooterCpt } from "@/core/components";

import AppVue from "@/AppVue.vue";

@Component({
  components: {
    FooterCpt: () => Promise.resolve(FooterCpt),
  },
})
export default class ResetPasswordView extends AppVue {
  disabled = false;
  displayMessage = false;
  loading = false;
  message = "";
  mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})");

  rules = {
    newPassword: [
      {
        required: true,
        message: "The new password is required",
        trigger: "blur",
      },
      { min: 8, message: "Length should be at least 8 characters", trigger: "blur" },
    ],
    confirmPassword: [
      {
        validator: (rule: any, value: string, callback: any) => {
          if (value === this.form.newPassword) {
            callback();
          } else {
            callback(new Error("Passwords don't match"));
          }
        },
      },
    ],
  };
  form = {
    email: "",
    newPassword: "",
    confirmPassword: "",
    token: "",
  };
  year = new Date().getFullYear();

  logo = images.cisLogoCropped;
  async created() {
    const email = this.$route.params.userEmailAddress;
    const token = decodeURIComponent(this.$route.params.emailConfirmationToken);
    this.form = {
      email,
      newPassword: "",
      confirmPassword: "",
      token,
    };
  }

  async mounted() {
    if (!this.$route.params.emailConfirmationToken) {
      // error this link is not valid
      this.displayMessage = true;
      this.message = "Error. The link you have followed is not valid.";
      return;
    } else {
    }
  }
  async confirmEmailAndSetPassword(confirmFormRef: any) {
    this.loading = true;
    this.disabled = true;
    this.displayMessage = false;
    try {
      const passwordValidation = this.mediumRegex.test(this.form.newPassword);
      const passwordMatch = this.form.newPassword === this.form.confirmPassword;
      if (!passwordValidation) {
        this.message = "The password is not strong enough";
        this.displayMessage = true;
        this.loading = false;
        this.disabled = false;
        return;
      }
      if (!passwordMatch) {
        this.message = "Passwords don't match";
        this.displayMessage = true;
        this.loading = false;
        this.disabled = false;
        return;
      }

      const response = await AccountService.restorePassword(this.form);
      AccountService.setToken(response.token);
      this.notify("Success", "The account has been updated.", "success");
      this.$router.push("/login").catch(() => {});
    } catch (error: any) {
      this.displayMessage = true;
      this.message = "Error setting the password for this user.";
    } finally {
      this.loading = false;
      this.disabled = false;
    }
  }
}
