



































































import { Message, Val } from "element-ui";
import { images } from "@/core/constants";
import { Component, Vue } from "vue-property-decorator";
import { AccountService } from "@/core/services";
import { FooterCpt } from "@/core/components";
import {} from "@/core/models";

import AppVue from "@/AppVue.vue";
import { ElForm } from "node_modules/element-ui/types/form";

@Component({
  components: {
    FooterCpt: () => Promise.resolve(FooterCpt),
  },
})
export default class ConfirmEmailView extends AppVue {
  disabled = false;
  displayMessage = false;
  loading = false;
  message = "";
  mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})");
  rules = {
    newPassword: [
      {
        required: true,
        message: "The new password is required",
        trigger: "blur",
      },
      { min: 8, message: "Length should be at least 8 characters", trigger: "blur" },
    ],
    confirmPassword: [
      {
        required: true,
        message: "The new password is required",
        trigger: "blur",
      },
      {
        validator: (rule: any, value: string, callback: any) => {
          if (value === this.form.newPassword) {
            callback();
          } else {
            callback(new Error("Passwords don't match"));
          }
        },
      },
    ],
  };
  form = {
    email: "",
    newPassword: "",
    confirmPassword: "",
    token: "",
  };
  year = new Date().getFullYear();
  confirming = true;

  logo = images.cisLogo;
  async created() {}

  async mounted() {
    if (!this.$route.params.emailConfirmationToken) {
      // error this link is not valid
      this.displayMessage = true;
      this.message = "Error. The link you have followed is not valid.";
      return;
    } else {
      this.loading = true;
      this.confirming = true;

      const emailAddress = decodeURIComponent(this.$route.params.userEmailAddress);
      const token = decodeURIComponent(this.$route.params.emailConfirmationToken);
      try {
        const response = await AccountService.confirmEmailAddress(token, emailAddress);
        this.confirming = false;
        this.form.token = response.token.token;
        this.form.email = response.email;
      } catch {
        // didn't work, hide the rest of the page.
        this.confirming = true;
      } finally {
        this.loading = false;
      }
    }
  }

  async setPassword() {
    (this.$refs["form"] as ElForm).validate((valid) => {
      if (!valid) {
        return false;
      } else {
        this.loading = true;
        this.disabled = true;

        const passwordValidation = this.mediumRegex.test(this.form.newPassword);
        const passwordMatch = this.form.newPassword === this.form.confirmPassword;
        if (!passwordValidation) {
          this.message = "The password is not strong enough";
          this.displayMessage = true;
          this.loading = false;
          this.disabled = false;
          return;
        }
        if (!passwordMatch) {
          this.message = "Passwords don't match";
          this.displayMessage = true;
          this.loading = false;
          this.disabled = false;
          return;
        }

        try {
          const response = AccountService.restorePassword(this.form);
          this.notify("Success", "The account has been updated. You may now log in.", "success");
          this.$router.push("/login").catch(() => {});
        } catch {
          this.displayMessage = true;
          this.message = "Error setting the password for this user.";
        } finally {
          this.loading = false;
          this.disabled = true;
        }
      }
    });
  }
}
